import { ExternalInteractorBase } from "tsparticles-engine";
import { Push } from "./Options/Classes/Push";
import { itemFromArray } from "tsparticles-engine";
export class Pusher extends ExternalInteractorBase {
    constructor(container) {
        super(container);
        this.handleClickMode = (mode) => {
            if (mode !== "push") {
                return;
            }
            const container = this.container, options = container.actualOptions, pushOptions = options.interactivity.modes.push;
            if (!pushOptions) {
                return;
            }
            const pushNb = pushOptions.quantity;
            if (pushNb <= 0) {
                return;
            }
            const group = itemFromArray([undefined, ...pushOptions.groups]), groupOptions = group !== undefined ? container.actualOptions.particles.groups[group] : undefined;
            container.particles.push(pushNb, container.interactivity.mouse, groupOptions, group);
        };
    }
    clear() {
    }
    init() {
    }
    async interact() {
    }
    isEnabled() {
        return true;
    }
    loadModeOptions(options, ...sources) {
        if (!options.push) {
            options.push = new Push();
        }
        for (const source of sources) {
            options.push.load(source === null || source === void 0 ? void 0 : source.push);
        }
    }
    reset() {
    }
}
